declare let global: any;
declare let window: Window & { global: any; process: any; buffer: any, url: any, crypto: any };

window.global = window;

import Buffer from 'buffer';
window.buffer = window.buffer || Buffer;
global.Buffer = global.Buffer || Buffer;

import * as process from 'process';
window.process = process;

import * as url from 'url';
window.url = url;
